import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const links = [
  { href: '/', label: 'Home' },
  { href: '/services', label: 'Services' },
  { href: '/about-pnpr', label: 'About PNPR' },
  { href: '/comment-and-resources', label: 'Comment and Resources' },
  { href: '/books', label: 'Books' },
  { href: '/contact', label: 'Contact' },
]

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <div className="container max-w-6xl text-center content">
      <h1>Link not found</h1>
      <p>The page you are trying to access doesn't exist or has moved.</p>
      <p>Please take a look at one of the pages below:</p>
      <ul>
        {links.map(link => (
          <li>
            <Link to={link.href}>{link.label}</Link>
          </li>
        ))}
      </ul>
    </div>
  </Layout>
)

export default NotFoundPage
